/** @format */

import React, { useEffect, useState } from "react";
import { Table, Space, message, Modal } from "antd";
import { Button } from "primereact/button";
import {
	DeleteUser,
	GetComapnyByUserId,
	GetUsers,
} from "../../services/Api/Api";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Box } from "@material-ui/core";
import moment from "moment/moment";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import "./Customers.css";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const User = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedSharedData, setSelectedSharedData] = useState([]);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 20,
		},
		sortField: null,
		sortOrder: null,
	});

	const columns = [
		{
			title: "S.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
			render: (_, record, index) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					{/* Display the verification status icon */}
					{record.is_verified ? (
						<CheckCircleOutlined
							style={{ color: "green", marginRight: "8px" }}
						/>
					) : (
						<CloseCircleOutlined style={{ color: "red", marginRight: "8px" }} />
					)}
					{index + 1} {/* Serial number */}
				</div>
			),
		},
		{
			title: "Name",
			dataIndex: ["user_profile", "name"],
			sorter: (a, b) => a.user_profile.name.localeCompare(b.user_profile.name),
			width: "15%",
		},
		{
			title: "Email",
			dataIndex: "email",
			width: "20%",
		},
		{
			title: "Mobile",
			dataIndex: ["user_profile", "mobile"],
			width: "20%",
			render: (mobile) => mobile || "---",
		},
		{
			title: "Data Shared",
			dataIndex: "is_shared_data",
			width: "10%",
			render: (is_shared_data, record) =>
				is_shared_data ? (
					<a
						onClick={() => {
							getCompanyData(record.id); // Fetch company data when "Yes" is clicked
							setIsModalVisible(true);
						}}
						style={{ color: "blue" }}
					>
						Yes
					</a>
				) : (
					"No"
				),
		},
		{
			title: "No. Of Courses Purchased",
			dataIndex: "courseEntryCount",
			width: "20%",
		},
		{
			title: "Account Creation Date",
			dataIndex: "created_at",
			width: "30%",
			render: (date) => moment(date).format("MM-DD-YYYY HH:mm A"),
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => (
				<Space size="middle">
					<Button
						icon="pi pi-eye"
						rounded
						outlined
						className="mr-2"
						style={{ margin: "0px", borderRadius: "25px" }}
						onClick={(event) => navigateToViewUser(event, record.id)}
					/>
					<Button
						icon="pi pi-trash"
						rounded
						outlined
						severity="danger"
						style={{ borderRadius: "25px", color: "red", borderColor: "red" }}
						onClick={() => handleDelete([record.id])}
					/>
				</Space>
			),
		},
	];

	// Get all users
	const getData = async (params = {}) => {
		try {
			setLoading(true);
			let result = await GetUsers(localStorage.getItem("adminToken"), params);
			// Adding index for serial number
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1,
			}));
			setData(newData);
			setUserBackupData(newData);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 403) {
				navigate("/error401");
				console.log("Access denied. You do not have the required permissions.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	const getCompanyData = async (id) => {
		try {
			let result = await GetComapnyByUserId(id);
			const dataWithIndex = result.data.data.map((item, index) => ({
				...item,
				autoIncrementId: index + 1,
			}));
			setSelectedSharedData(dataWithIndex);
			console.log("userbooking==>", dataWithIndex);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getCompanyData();
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const onSearch = (searchField) => {
		const LIST = [...userBackupData];
		const searchList = [];

		for (let i in LIST) {
			if (
				LIST[i]?.user_profile?.name
					?.toLowerCase()
					?.includes(searchField?.toLowerCase()) ||
				LIST[i]?.email?.toLowerCase()?.includes(searchField?.toLowerCase())
			) {
				searchList.push(LIST[i]);
			}
		}

		setData(searchList);
	};

	const handleDelete = (userIds) => {
		Modal.confirm({
			title: "Confirm",
			content: `Are you sure you want to delete ${
				userIds.length > 1 ? "these users" : "this user"
			}?`,
			onOk: async () => {
				try {
					await DeleteUser(userIds, localStorage.getItem("adminToken"));
					message.success("Employee(s) deleted successfully");
					getData();
				} catch (error) {
					console.error("Error deleting employee(s):", error);
					message.error("Error deleting employee(s)");
				}
			},
		});
	};

	const exportToCSV = () => {
		const csvContent =
			"data:text/csv;charset=utf-8," +
			"ID,Name,Mobile,Email\n" +
			data
				.map(
					(row) =>
						`${row.id},${row.user_profile.name},${row.user_profile.mobile},${row.email}`
				)
				.join("\n");

		const encodedURI = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedURI);
		link.setAttribute("download", "customers.csv");
		document.body.appendChild(link);
		link.click();
	};

	const navigateToAddUser = () => {
		navigate("/addUser");
	};

	const navigateToViewUser = (event, id) => {
		navigate(`/viewUser/${id}`);
	};

	const handleModalClose = () => {
		setIsModalVisible(false);
		setSelectedSharedData([]);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys) => {
			setSelectedRowKeys(selectedRowKeys);
		},
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">USER MANAGEMENT</h3>
					<p className="page-sub-title">View, delete, and add User</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								type="search"
								onChange={(e) => {
									onSearch(e.target.value);
								}}
								placeholder="Search..."
							/>
						</span>

						<Button
							icon="pi pi-cloud-download"
							severity="success"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={exportToCSV}
						/>
						<Button
							icon="pi pi-trash"
							severity="danger"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
								cursor: "pointer",
							}}
							onClick={() => handleDelete(selectedRowKeys)}
							disabled={!selectedRowKeys.length}
						/>
						<Button
							icon="pi pi-plus"
							severity="info"
							style={{
								margin: "0px 10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={navigateToAddUser}
						/>
					</Box>
				</Box>
			</Box>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={data}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
				rowSelection={rowSelection}
			/>

			<Modal
				centered
				width={800}
				title="Shared Data Details"
				visible={isModalVisible}
				onCancel={handleModalClose}
				footer={null}
			>
				<Table
					columns={[
						{
							title: "Company Name",
							dataIndex: ["corporate_shared_data", "company_name"],
							key: "company_name",
						},
						{
							title: "Company Owner Name",
							dataIndex: ["corporate_shared_data", "name"],
							key: "company_owner_name",
						},
						{
							title: "Company Owner Email",
							dataIndex: ["corporate_shared_data", "email"],
							key: "company_owner_email",
						},
						{
							title: "",
							key: "redirect",
							render: (text, record) => (
								<IoArrowForwardCircleOutline
									style={{ fontSize: "20px", cursor: "pointer" }}
									className="redirect_button"
									onClick={() => navigate(`/viewCorporate/${record.corporate_id}`)}
								/>
							),
						},
					]}
					dataSource={selectedSharedData}
					rowKey={(record) => record.id}
					pagination={false}
				/>
			</Modal>
		</Box>
	);
};

export default User;
