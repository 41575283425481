/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Box } from "@mui/material";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { message } from "antd";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
	GetAllCourseName,
	GetTestById,
	UpdateTest,
} from "../../services/Api/CourseApi";
import { BASE_URL_IMAGE } from "../../services/Host";

const EditTest = () => {
	const { id } = useParams();
	const [courseName, setCourseName] = useState([]);
	const [courseData, setCourseData] = useState({
		name: "",
		test_attachments: [],
		price: "",
		duration: "",
		course_id: "",
		test_course: {},
	});
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [removeImageId, setRemoveImageId] = useState(null);
	const [disable, setDisable] = useState(false);
	const [duration, setDuration] = useState(5); // Initialize with default 5 minutes
	const navigate = useNavigate();

	useLayoutEffect(() => {
		GetTestById(id)
			.then((res) => {
				const data = res.data.data;
				setCourseData({
					name: data.name,
					// test_attachments: data.test_attachments || [],
					price: data.price,
					duration: data.duration,
					course_id: data.course_id,
					test_course: data.test_course,
				});
				setDuration(data.duration || 5); // Set duration from API, default to 5 if undefined
				if (data.test_attachments && data.test_attachments.length > 0) {
					const attachment = data.test_attachments[0];
					setImagePreview(`${BASE_URL_IMAGE}${attachment.file_name}`);
					setRemoveImageId(attachment.id);
				}
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);

	useLayoutEffect(() => {
		GetAllCourseName()
			.then((res) => {
				setCourseName(res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, []);

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setImage(file);
			const previewUrl = URL.createObjectURL(file);
			setImagePreview(previewUrl);
		}
	};

	const handleRemoveImage = () => {
		setImage(null);
		setImagePreview(null);
		setRemoveImageId(null);
		document.getElementById("imageInput").value = "";
	};

	const navigateToCourse = () => {
		navigate("/test");
	};

	const navigateToAddQuestions = (event, id) => {
		navigate(`/addQuestions/${id}`);
	};

	const onChange = (e) => {
		setCourseData({ ...courseData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setDisable(true);

		const formData = new FormData();
		formData.append("course_id", courseData.course_id || "");
		formData.append("name", courseData.name || "");
		formData.append("duration", duration || ""); // Send updated duration value
		// formData.append("price", courseData.price || "");

		if (image) {
			formData.append("images", image);
		}

		if (removeImageId) {
			formData.append("removeImageIds", removeImageId);
		}

		UpdateTest(id, formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Test updated successfully!");
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			})
			.finally(() => {
				setDisable(false);
			});
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">TEST MANAGEMENT</h3>
					<p className="page-sub-title">Update Test Details</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToCourse}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Test</span>
					</Button>
				</div>
			</Box>
			<Form onSubmit={handleSubmit}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					{/* <Card style={{ width: "30%", marginRight: "20px" }}>
						<Form.Group className="mb-3">
							<Form.Label>Image:</Form.Label>
							<Form.Control
								type="file"
								accept="image/*"
								id="imageInput"
								onChange={handleImageChange}
							/>
						</Form.Group>

						{imagePreview && (
							<div
								style={{
									border: "1px solid #ccc",
									borderRadius: "5px",
									padding: "10px",
									marginBottom: "10px",
								}}
							>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										crossOrigin="anonymous"
										src={imagePreview}
										alt="Preview"
										style={{
											height: "80px",
											width: "80px",
											marginRight: "10px",
										}}
									/>
									<DeleteIcon
										fontSize="large"
										color="warning"
										style={{ marginLeft: "auto", cursor: "pointer" }}
										onClick={handleRemoveImage}
									/>
								</div>
							</div>
						)}
					</Card> */}
					<Card style={{ width: "100%" }}>
						<Row>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Course Name:</Form.Label>
									<Form.Select
										aria-label="Default select example"
										name="course_id"
										value={courseData.course_id} // Bind the value to the state
										onChange={onChange}
										required
									>
										<option value="">Select Course Name</option>
										{courseName.map((course) => (
											<option key={course.id} value={course.id} disabled={course.haveTest}>
												{course.name}
												
											</option>
										))}
									</Form.Select>
									{!courseData.course_id && <p>No course selected yet</p>}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Test Name:</Form.Label>
									<Form.Control
										name="name"
										defaultValue={courseData.name}
										type="text"
										onChange={onChange}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Duration:</Form.Label>
									<Form.Range
										name="duration"
										value={duration}
										onChange={(e) => setDuration(e.target.value)}
										min="5"
										max="240"
										step="5"
										className="new_form_control"
									/>
									<Form.Text muted>{duration} minutes</Form.Text>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							{/* <Col>
								<Form.Group className="mb-3">
									<Form.Label>Price:</Form.Label>
									<Form.Control
										name="price"
										defaultValue={courseData.price}
										type="number"
										min="0"
										max="999"
										onChange={(e) => {
											let value = parseInt(e.target.value, 10);
											if (isNaN(value)) value = 0; // If not a number, set to 0
											if (value > 999) value = 999; // Limit to 99 if above
											if (value < 0) value = 0; // Limit to 0 if below
											e.target.value = value; // Update the input value
											onChange(e); // Call the original onChange handler
										}}
									/>
									<Form.Text muted>
										You can enter a price between 0 and 999.
									</Form.Text>
								</Form.Group>
							</Col> */}
						</Row>
					</Card>
				</div>

				<div style={{ display: "flex", flexDirection: "row-reverse" }}>
					<Button
						label="Next"
						icon="pi pi-arrow-right"
						iconPos="right"
						severity="secondary"
						onClick={(event) => navigateToAddQuestions(event, id)}
						style={{
							height: "45px",
							marginLeft: "10px",
							padding: "20px",
							borderRadius: "5px",
							marginTop: "20px",
						}}
					/>

					<Button
						label="Cancel"
						icon="pi pi-times"
						severity="secondary"
						onClick={navigateToCourse}
						style={{
							height: "45px",
							marginLeft: "10px",
							padding: "20px",
							borderRadius: "5px",
							marginTop: "20px",
						}}
					/>

					<Button
						label="Update Test"
						icon="pi pi-check"
						severity="success"
						style={{
							height: "45px",
							padding: "20px",
							borderRadius: "5px",
							marginTop: "20px",
						}}
						type="submit"
						disabled={disable}
					/>
				</div>
			</Form>
		</Box>
	);
};

export default EditTest;
