import React, { useEffect, useState } from "react";
import { Table, Space, message, Modal } from "antd";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Box } from "@material-ui/core";
import { DeleteCorporate, GetCorporate } from "../../services/Api/Corporate";
import moment from "moment";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const Corporate = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]); // For handling selected rows
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 20,
		},
		sortField: null,
		sortOrder: null,
	});

	const columns = [
		{
			title: "S.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
			render: (_, record, index) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					{/* Display the verification status icon */}
					{record.is_verified ? (
						<CheckCircleOutlined
							style={{ color: "green", marginRight: "8px" }}
						/>
					) : (
						<CloseCircleOutlined style={{ color: "red", marginRight: "8px" }} />
					)}
					{index + 1} {/* Serial number */}
				</div>
			),
		},
		{
			title: "Owner Name",
			dataIndex: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
			width: "20%",
		},
		{
			title: "Email",
			dataIndex: "email",
			width: "30%",
		},
		{
			title: "Company Name",
			dataIndex: "company_name",
			width: "20%",
		},
		{
			title: "Number Of Employees",
			dataIndex: "number_of_employees",
			width: "10%",
		},
		{
			title: "Account Creation Date",
			dataIndex: "created_at",
			width: "30%",
			render: (date) => moment(date).format("MM-DD-YYYY HH:mm A"),
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => (
				<Space size="middle">
					<Button
						icon="pi pi-eye"
						rounded
						outlined
						className="mr-2"
						style={{ margin: "0px", borderRadius: "25px" }}
						onClick={(event) => navigateToViewUser(event, record.id)}
					/>
					<Button
						icon="pi pi-trash"
						rounded
						outlined
						severity="danger"
						style={{ borderRadius: "25px", color: "red", borderColor: "red" }}
						onClick={() => handleDelete([record.id])}
					/>
				</Space>
			),
		},
	];

	// Get all users
	const getData = async (params = {}) => {
		try {
			setLoading(true);
			let result = await GetCorporate(
				localStorage.getItem("adminToken"),
				params
			);
			// Adding index for serial number
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1,
			}));
			setData(newData);
			setUserBackupData(newData);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 403) {
				navigate("/error401");
				console.log("Access denied. You do not have the required permissions.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const onSearch = (searchField) => {
		const LIST = [...userBackupData];
		const searchList = [];

		for (let i in LIST) {
			if (
				LIST[i]?.name?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
				LIST[i]?.email?.toLowerCase()?.includes(searchField?.toLowerCase())
			) {
				searchList.push(LIST[i]);
			}
		}

		setData(searchList);
	};
	const handleDelete = (userIds) => {
		Modal.confirm({
			title: "Confirm",
			content: `Are you sure you want to delete ${
				userIds.length > 1 ? "these corporates" : "this corporate"
			}?`,
			onOk: async () => {
				try {
					await DeleteCorporate(userIds, localStorage.getItem("adminToken"));
					message.success("Employee(s) deleted successfully");
					getData();
				} catch (error) {
					console.error("Error deleting employee(s):", error);
					message.error("Error deleting employee(s)");
				}
			},
		});
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys) => {
			setSelectedRowKeys(selectedRowKeys);
		},
	};

	const exportToCSV = () => {
		const csvContent =
			"data:text/csv;charset=utf-8," +
			"ID,Name,Email\n" +
			data.map((row) => `${row.id},${row.name},${row.email}`).join("\n");

		const encodedURI = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedURI);
		link.setAttribute("download", "corporates.csv");
		document.body.appendChild(link);
		link.click();
	};

	const navigateToAddUser = () => {
		navigate("/addCorporate");
	};

	const navigateToViewUser = (event, id) => {
		navigate(`/viewCorporate/${id}`);
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">CORPORATE MANAGEMENT</h3>
					<p className="page-sub-title">View, delete, and add Corporate</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								type="search"
								onChange={(e) => {
									onSearch(e.target.value);
								}}
								placeholder="Search..."
							/>
						</span>
						<Button
							icon="pi pi-cloud-download"
							severity="success"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={exportToCSV}
						/>
						<Button
							icon="pi pi-plus"
							severity="info"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={navigateToAddUser}
						/>
						<Button
							icon="pi pi-trash"
							severity="danger"
							style={{
								margin: "0px 10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={() => handleDelete(selectedRowKeys)}
							disabled={!selectedRowKeys.length}
						/>
					</Box>
				</Box>
			</Box>
			<Table
				rowSelection={rowSelection} // Add row selection
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={data}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
			/>
		</Box>
	);
};

export default Corporate;
