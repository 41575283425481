/** @format */

import React, { useState, useRef, useLayoutEffect } from "react";
import { Box } from "@mui/material";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { message } from "antd";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { Row, Col } from "react-bootstrap";
import { CreateTest, GetAllCourseName } from "../../services/Api/CourseApi";

const AddTest = () => {
	const [name, setName] = useState("");
	const [courseId, setCourseId] = useState("");
	const [duration, setDuration] = useState("");
	const [price, setPrice] = useState("");
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [disable, setDisable] = useState(false);
	const [courseData, setCourseData] = useState([]);
	const navigate = useNavigate();

	// Reference for CKEditor instance
	const editorRef = useRef();

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!name) {
			message.error("Please enter Course Name");
			setDisable(false);
			return;
		}

		if (!courseId) {
			message.error("Please select Course Name");
			setDisable(false);
			return;
		}
		if (!duration) {
			message.error("Please enter Duration");
			setDisable(false);
			return;
		}
		// if (!price) {
		// 	message.error("Please enter Price");
		// 	setDisable(false);
		// 	return;
		// }
		// if (!image) {
		// 	message.error("Please insert Image");
		// 	setDisable(false);
		// 	return;
		// }

		try {
			const formData = new FormData();
			formData.append("course_id", courseId);
			formData.append("name", name);
			formData.append("duration", duration);
			// formData.append("price", price);
			// formData.append("images", image, `compressed_image.jpg`);

			const response = await CreateTest(formData);

			if (response.status === 201) {
				message.success("Test added successfully!");
				const courseId = response.data.data.id;
				navigate(`/addQuestions/${courseId}`);
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 400) {
					message.error("This test already exists.");
				} else if (error.response.status === 401) {
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 1000);
				} else {
					message.error("Something went wrong");
				}
			}
		} finally {
			setDisable(false);
		}
	};

	const handleImageChange = async (e) => {
		const file = e.target.files[0];

		if (file) {
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

			if (!allowedTypes.includes(file.type)) {
				message.error(
					"Error: Invalid file type. Images (JPEG, JPG, PNG) only!"
				);
				return;
			}

			const compressedFile =
				file.size <= 1024 * 1024
					? file
					: await imageCompression(file, {
							maxSizeMB: 1,
							maxWidthOrHeight: 1920,
					  });

			const compressedImage = new Blob([compressedFile], {
				type: "image/jpeg",
			});

			setImage(compressedImage);

			// Create image preview URL
			const previewUrl = URL.createObjectURL(compressedFile);
			setImagePreview({ name: file.name, previewUrl });
		}
	};

	const handleRemoveImage = () => {
		setImage(null);
		setImagePreview(null);
		document.getElementById("imageInput").value = ""; // Clear the file input
	};

	useLayoutEffect(() => {
		GetAllCourseName()
			.then((res) => {
				setCourseData(res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, []);

	const navigateToCourse = () => {
		navigate("/test");
	};

	const handleClear = () => {
		setName("");
		setDuration("");
		setPrice("");
		setImage(null);
		setImagePreview(null);
		document.getElementById("imageInput").value = "";

		// Clear the CKEditor content
		if (editorRef.current) {
			editorRef.current.setData(""); // Ensure editorRef.current is used correctly
		}
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">TEST MANAGEMENT</h3>
					<p className="page-sub-title">Create New Test</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToCourse}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Test</span>
					</Button>
				</div>
			</Box>
			<Form onSubmit={handleSubmit}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					{/* <Card style={{ width: "30%", marginRight: "20px" }}>
						<Form.Group className="mb-3">
							<Form.Label>Image:</Form.Label>
							<Form.Control
								type="file"
								required
								accept="image/*"
								id="imageInput"
								onChange={handleImageChange}
							/>
						</Form.Group>

						{imagePreview && (
							<div
								style={{
									border: "1px solid #ccc",
									borderRadius: "5px",
									padding: "10px",
									marginBottom: "10px",
								}}
							>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										src={imagePreview.previewUrl}
										alt="Preview"
										style={{
											height: "80px",
											width: "80px",
											marginRight: "10px",
										}}
									/>
									<span>{imagePreview.name}</span>
									<DeleteIcon
										fontSize="large"
										color="warning"
										style={{ marginLeft: "auto", cursor: "pointer" }}
										onClick={handleRemoveImage}
									/>
								</div>
							</div>
						)}
					</Card> */}
					<Card style={{ width: "100%" }}>
						<Row>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Course Name:</Form.Label>

									{/* <Form.Select
										aria-label="Default select example"
										value={courseId} // Bind the value to the state
										onChange={(e) => setCourseId(e.target.value)} // Update courseId on change
										required // Make it a required field
									>
										<option value="">Select Course Name</option>
										{courseData?.map((course) => (
											<option key={course.id} value={course.id}>
												{course?.name}
											</option>
										))}
									</Form.Select> */}
									<Form.Select
										aria-label="Default select example"
										value={courseId}
										onChange={(e) => setCourseId(e.target.value)}
										required
									>
										<option value="">Select Course Name</option>
										{courseData?.map((course) => (
											<option
												key={course.id}
												value={course.id}
												disabled={course.haveTest} // Disable if haveTest is true
											>
												{course?.name}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Test Name:</Form.Label>
									<Form.Control
										type="text"
										required
										placeholder="Enter Test Name"
										value={name}
										onChange={(e) => {
											if (e.target.value.length <= 255) {
												setName(e.target.value);
											} else {
												message.error("Test Name limit exceeds 255 characters");
											}
										}}
										className="new_form_control"
									/>
									<Form.Text muted>{name.length} / 255 characters</Form.Text>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Test Duration: {duration} minutes</Form.Label>
									<Form.Range
										required
										value={duration || 5}
										onChange={(e) => setDuration(e.target.value)}
										min="5"
										max="240"
										step="5"
										className="new_form_control"
									/>
								</Form.Group>
							</Col>
						</Row>
						{/* <Col>
								<Form.Group className="mb-3">
									<Form.Label>Price:</Form.Label>
									<Form.Control
										type="number"
										required
										placeholder="Enter Price"
										value={price}
										onChange={(e) => {
											const value = parseInt(e.target.value, 10);
											// Ensure value is between 0 and 999
											if (value >= 0 && value <= 999) {
												setPrice(value);
											} else if (e.target.value === "") {
												setPrice(""); // Allow clearing the input
											}
										}}
										min="0"
										max="999"
										className="new_form_control"
									/>
									<Form.Text muted>
										You can enter a price between 0 and 999.
									</Form.Text>
								</Form.Group>
							</Col> */}
					</Card>
				</div>
				<div style={{ display: "flex", flexDirection: "row-reverse" }}>
					<Button
						severity="info"
						onClick={handleSubmit}
						disabled={disable}
						style={{
							height: "45px",
							marginLeft: "10px",
							padding: "20px",
							borderRadius: "5px",
							marginTop: "20px",
						}}
					>
						{disable ? "Saving...." : "Save & Next"}
					</Button>
					<Button
						severity="secondary"
						style={{
							height: "45px",
							marginLeft: "10px",
							padding: "20px",
							borderRadius: "5px",
							marginTop: "20px",
						}}
						onClick={handleClear}
					>
						Clear
					</Button>
				</div>
			</Form>
		</Box>
	);
};

export default AddTest;
